import {
  SET_CARDS_USER,
  SET_CARD_SENDING,
  SET_CARDS_LIST_CANCEL_TOKEN,
  START_OBTAIN_CARDS_LIST,
  OBTAIN_CARDS_LIST_DATA,
} from "../actions";

const initialState = {
  cardsUser: null,
  isCardSending: false,
  cardsList: [],
  cardsListError: [],
  cardsListNextCursor: null,
  cardsListCancelToken: null,
  cardsListIsLoading: true,
};

export default function cardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CARDS_USER:
      return { ...state, cardsUser: action.payload };
    case SET_CARD_SENDING:
      return { ...state, isCardSending: action.payload };
    case SET_CARDS_LIST_CANCEL_TOKEN:
      return { ...state, cardsListCancelToken: action.payload };
    case START_OBTAIN_CARDS_LIST:
      return { ...state, cardsListIsLoading: true };
    case OBTAIN_CARDS_LIST_DATA:
      return {
        ...state,
        cardsListIsLoading: false,
        cardsList: action.payload.data,
        cardsListNextCursor: action.payload.nextCursor,
        cardsListError: [],
      };

    default:
      return state;
  }
}
