import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "./redux/reducers/login";
import profileReducer from "./redux/reducers/profile";
import contactsReducer from "./redux/reducers/contacts";
import cardsReducer from "./redux/reducers/cards";
import dialogsReducer from "./redux/reducers/dialogs";
import fileUploadReducer from "./redux/reducers/fileUpload";
import chatBoxReducer from "./redux/reducers/chatBox";
import supportReducer from "./redux/reducers/support";
import smileReducer from "./redux/reducers/smile";
import snackbarNotificationReducer from "./redux/reducers/snackbarNotification";
import invoicesReducer from "./redux/reducers/invoices";
import notificationsReducer from "./redux/reducers/notifications";
// import { commonApi } from "./redux/commonApi";

const appReducer = combineReducers({
  // [commonApi.reducerPath]: commonApi.reducer,
  login: loginReducer,
  profile: profileReducer,
  contacts: contactsReducer,
  cards: cardsReducer,
  dialogs: dialogsReducer,
  invoices: invoicesReducer,
  notifications: notificationsReducer,
  fileUpload: fileUploadReducer,
  chatBox: chatBoxReducer,
  support: supportReducer,
  smile: smileReducer,
  snackbarNotification: snackbarNotificationReducer,
});

const store = configureStore({
  reducer: appReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(commonApi.middleware),
});

export default store;
