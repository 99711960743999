// import { push } from "connected-react-router";
import { API } from "../../modules/Api.js";
import Logger from "../../modules/Logger";
import { closeSocket } from "../../modules/Socket";
// import { obtainProfileData } from "./profileAction";
export const SET_AUTH = "SET_AUTH";
export const START_LOGIN = "START_LOGIN";
export const START_FINISH_REGISTER = "START_FINISH_REGISTER";
export const OBTAIN_LOGIN_DATA = "OBTAIN_LOGIN_DATA";
export const OBTAIN_FINISH_DATA = "OBTAIN_FINISH_DATA";
export const OBTAIN_LOGIN_ERROR = "OBTAIN_LOGIN_ERROR";

// Login
function startLogin() {
  return { type: START_LOGIN };
}

function obtainLoginData() {
  return { type: OBTAIN_LOGIN_DATA };
}

function obtainLoginError(error) {
  return {
    type: OBTAIN_LOGIN_ERROR,
    payload: error,
  };
}

export function login(email, password, onSuccess) {
  return (dispatch) => {
    dispatch(startLogin());

    let loginData = {
      email: email,
      password: password,
    };

    return API.post("auth/login", loginData).then(
      (response) => {
        Logger.info(response);
        dispatch(obtainLoginData());
        onSuccess();
      },
      (error) => {
        // Logger.info(error);
        dispatch(obtainLoginError(error));
      },
    );
  };
}

// Register Finished
function startFinishRegister() {
  return { type: START_FINISH_REGISTER };
}

function obtainFinishData() {
  return { type: OBTAIN_FINISH_DATA };
}

export function finishRegister(token, onSuccess) {
  return (dispatch) => {
    dispatch(startFinishRegister());

    let finishData = {
      token: token,
    };

    return API.post("/user/finish_registration", finishData).then(
      (response) => {
        Logger.info(response);
        dispatch(obtainFinishData());
        onSuccess();
      },
      (error) => {
        Logger.info(error);
      },
    );
  };
}

// Logout
export function logout() {
  return (dispatch) => {
    localStorage.clear();
    closeSocket();
    dispatch(setAuth());
  };
}

export function setAuth(force = false) {
  return {
    type: SET_AUTH,
    payload: !force ? !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER) : false,
  };
}
