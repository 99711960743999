import { io } from "socket.io-client";
import Logger from "./Logger";
import { getSingleChatBoxMessage, getSupportList, getSmileList } from "../redux/actions";
let socket, socketStore, queryClient;

export const getSocket = () => socket;
export const closeSocket = () => (socket ? socket.close() : null);
export const createSocketConnection = (store, queryClientInstance) => {
  socketStore = store;
  queryClient = queryClientInstance;

  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications`);

  attachServiceCommands();

  return socket;
};

export const reconnectSocket = (userId) => {
  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications?id=${userId}`);

  attachAppCommands();

  return socket;
};

const attachAppCommands = () => {
  socket.on("message", (data) => {
    Logger.info("Socket on message", data);
    const accountType = socketStore.getState().profile.account_type;
    const currentUser = socketStore.getState().chatBox.currentUser;
    if (accountType === "owner") {
      queryClient.invalidateQueries("SidebarContacts");
    }
    if (currentUser === data.sender_id) {
      socketStore.dispatch(getSingleChatBoxMessage(data.message_id));
    }
  });

  socket.on("notifications", (data) => {
    Logger.info("Socket on support/smileassesment", data);
    switch (data.type) {
      case "SupportRequestCreate":
        console.log("support notifications");
        socketStore.dispatch(getSupportList(1));
        break;
      case "SmileAssessmentRequestCreate":
        console.log("smile notifications");
        socketStore.dispatch(getSmileList(1));
        break;
      default:
        console.log("none notifications", data);
    }
  });

  socket.on("cards", (data) => {
    Logger.info("Socket on cards", data);
    let accountType = socketStore.getState().profile.account_type;
    switch (data.type) {
      case "CardCreate":
        if (accountType === "owner") {
          const currentUser = socketStore.getState().cards.cardsUser;
          if (parseInt(currentUser) === parseInt(data.sender_id)) {
            queryClient.invalidateQueries("Cards");
          }
        }
        break;
      case "CardUpdate":
        if (accountType === "owner") {
          const currentUser = socketStore.getState().cards.cardsUser;
          if (parseInt(currentUser) === parseInt(data.sender_id)) {
            queryClient.invalidateQueries("Cards");
          }
        }
        break;
      default:
        Logger.info("Uncatched socket cards event", data);
    }
  });

  // Notifications
  socket.on("notifications", (data) => {
    Logger.info("New socket notifications", data);
    switch (data.type) {
      case "NewNotification":
        queryClient.invalidateQueries("UnreadNotifications");
        queryClient.invalidateQueries("TopBarNotifications");
        break;
      default:
        console.log("not handled team event", data);
    }
  });
};

export const socketEmmit = (type, payload) => {
  if (socket) {
    socket.emit(type, payload);
  }
};

const attachServiceCommands = () => {
  // Service commands
  socket.on("connect", () => {
    Logger.info("SOCKET CONNECTION connected (openned) => ", socket);
  });
  socket.on("disconnect", () => {
    Logger.info("SOCKET CONNECTION disconnect => ", socket ? socket.connected : null);
  });
  socket.on("connect_error", (error) => {
    Logger.info("SOCKET connect_error => ", JSON.stringify(error));
  });
  socket.on("connect_timeout", (timeout) => {
    Logger.info("SOCKET connect_timeout => ", timeout);
  });
  socket.on("error", (error) => {
    Logger.info("SOCKET error => ", error);
  });
  socket.on("ping", () => {
    Logger.info("SOCKET ping");
  });
  socket.on("pong", (latency) => {
    Logger.info("SOCKET pong => ", latency);
  });

  // Reconnect
  socket.on("reconnect", (attempt) => {
    Logger.info("SOCKET reconnect => ", attempt);
  });
  socket.on("reconnect_attempt", (res) => {
    Logger.info("SOCKET reconnect_attempt => ", res);
  });
  socket.on("reconnecting", (attempt) => {
    Logger.info("SOCKET reconnecting => ", attempt);
  });
  socket.on("reconnect_error", (error) => {
    Logger.info("SOCKET reconnect_error => ", JSON.stringify(error));
  });
  socket.on("reconnect_failed", () => {
    Logger.info("SOCKET reconnect_failed => ");
  });
};
