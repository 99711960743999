export const SET_SNACKBAR_NOTIFICATION = "SET_SNACKBAR_NOTIFICATION";

function setSnackbarNotification(notification) {
  return {
    type: SET_SNACKBAR_NOTIFICATION,
    payload: notification,
  };
}

export const toggleSnackbarNotification = (notification) => (dispatch) => {
  dispatch(setSnackbarNotification(notification));
};
