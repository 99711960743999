import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./i18n";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { createSocketConnection } from "./modules/Socket";

// import * as serviceWorker from "./registerServiceWorker"; // dont use service worker for production
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

createSocketConnection(store, queryClient);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>,
    rootElement,
  );
} else {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>,
    rootElement,
  );
}

// serviceWorker.unregister();
