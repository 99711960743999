import {
  TOGGLE_REFINEMENT_DIALOG,
  TOGGLE_REPLACEMENT_DIALOG,
  TOGGLE_DELETE_DIALOG,
  TOGGLE_REPLACEMENT_REQUEST_DIALOG,
  TOGGLE_CONFIRM_DIALOG,
  TOGGLE_WARRANTY_DIALOG,
  TOGGLE_UPLOAD_STL_DIALOG,
  TOGGLE_UPLOAD_SHIPING_DIALOG,
  TOGGLE_IMAGE_PREVIEW_DIALOG,
  TOGGLE_CHANGE_EMAIL_DIALOG,
  TOGGLE_CHANGE_PASSWORD_DIALOG,
  TOGGLE_CLOSE_DIALOG,
} from "../actions";

const initialState = {
  isRefinementDialog: false,
  isReplacementDialog: false,
  isDeleteDialog: false,
  isDeleteUserId: null,
  isDeleteItemId: null,
  isReplacementRequestDialog: false,
  isConfirmDialog: false,
  isCardId: null,
  isWarrantyDialog: false,
  isUploadStlDialog: false,
  isUploadShipingDialog: false,
  isChangeEmailDialog: false,
  isChangePasswordDialog: false,
  isCloseDialog: false,
};

export default function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_REFINEMENT_DIALOG:
      return {
        ...state,
        isRefinementDialog: action.payload.isShow,
      };
    case TOGGLE_REPLACEMENT_DIALOG:
      return {
        ...state,
        isReplacementDialog: action.payload.isShow,
      };
    case TOGGLE_DELETE_DIALOG:
      return {
        ...state,
        isDeleteDialog: action.payload.isShow,
        isDeleteUserId: action.payload.isDeleteUserId,
        isDeleteItemId: action.payload.isDeleteItemId,
      };
    case TOGGLE_REPLACEMENT_REQUEST_DIALOG:
      return {
        ...state,
        isReplacementRequestDialog: action.payload.isShow,
      };
    case TOGGLE_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialog: action.payload.isShow,
        isCardId: action.payload.isCardId,
      };
    case TOGGLE_WARRANTY_DIALOG:
      return {
        ...state,
        isWarrantyDialog: action.payload.isShow,
      };
    case TOGGLE_UPLOAD_STL_DIALOG:
      return {
        ...state,
        isUploadStlDialog: action.payload.isShow,
      };
    case TOGGLE_UPLOAD_SHIPING_DIALOG:
      return {
        ...state,
        isUploadShipingDialog: action.payload.isShow,
      };
    case TOGGLE_CLOSE_DIALOG:
      return {
        ...state,
        isCloseDialog: action.payload.isShow,
      };
    case TOGGLE_IMAGE_PREVIEW_DIALOG:
      return {
        ...state,
        isImagePreview: action.payload.isShow,
        imagePreviewArray: action.payload.imagePreviewArray,
        imagePreviewIndex: action.payload.imagePreviewIndex,
      };
    case TOGGLE_CHANGE_EMAIL_DIALOG:
      return {
        ...state,
        isChangeEmailDialog: action.payload,
      };
    case TOGGLE_CHANGE_PASSWORD_DIALOG:
      return {
        ...state,
        isChangePasswordDialog: action.payload,
      };
    default:
      return state;
  }
}
