import { API } from "../../modules/Api";
import Logger from "../../modules/Logger";

export const OBTAINT_NOTIFICATIONS_DATA = "OBTAINT_NOTIFICATIONS_DATA";
export const OBTAINT_NOTIFICATIONS_OBJECT_DATA = "OBTAINT_NOTIFICATIONS_OBJECT_DATA";
export const OBTAINT_NOTIFICATIONS_UNREAD_COUNT_DATA = "OBTAINT_NOTIFICATIONS_UNREAD_COUNT_DATA";
export const OBTAINT_NOTIFICATIONS_ERROR = "OBTAINT_NOTIFICATIONS_ERROR";
export const OBTAINT_NOTIFICATIONS_DATA_LOADING = "OBTAINT_NOTIFICATIONS_DATA_LOADING";
export const OBTAINT_NOTIFICATIONS_CANCEL_TOKEN = "OBTAINT_NOTIFICATIONS_CANCEL_TOKEN";
export const OBTAINT_NOTIFICATIONS_OBJECT_CANCEL_TOKEN = "OBTAINT_NOTIFICATIONS_OBJECT_CANCEL_TOKEN";
export const SET_USER_UNREAD_NOTIFICATIONS = "SET_USER_UNREAD_NOTIFICATIONS";

function setUserUnreadNotifications(counter) {
  return {
    type: SET_USER_UNREAD_NOTIFICATIONS,
    payload: counter,
  };
}

export const getUserUnreadNotifications = () => (dispatch) => {
  API.get("events/journal/user_events", {}).then(
    (response) => {
      Logger.info(response.data);
      dispatch(setUserUnreadNotifications(response.data.unread_notifications));
    },
    (error) => {
      Logger.info(error);
      // dispatch(setUserUnreadNotifications(0));
    },
  );
};
