import {
  START_OBTAIN_PROFILE_DATA,
  OBTAIN_PROFILE_DATA,
  OBTAIN_PROFILE_ERROR,
  // TOGGLE_SWITCH_PROFILE_CONTENT,
  // TOGGLE_SWITCH_MOBILE_SIDE_BAR,
  // START_GET_PROFILE,
} from "../actions";

const initialState = {
  isLoading: true,
  id: null,
  account_type: "",
  avatar: "",
  name: "",
  clinic: "",
  phone_number: "",
  country: "",
  shipping_address: "",
  send_email_notifications: "",
  errors: [],
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case START_OBTAIN_PROFILE_DATA:
      return {
        ...state,
        isLoading: true,
        errors: [],
      };
    case OBTAIN_PROFILE_DATA:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        errors: [],
      };
    case OBTAIN_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
