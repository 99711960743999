import { API } from "../../modules/Api";
import Logger from "../../modules/Logger";
import { CancelToken } from "axios";

export const SET_INVOICES_DATA_LOADING = "SET_INVOICES_DATA_LOADING";
export const OBTAIN_INVOICES_DATA = "OBTAIN_INVOICES_DATA";
export const OBTAIN_INVOICES_ERROR = "OBTAIN_INVOICES_ERROR";
export const SET_INVOICES_CANCEL_TOKEN = "SET_INVOICES_CANCEL_TOKEN";

function setInvoicesDataLoading(isLoading) {
  return {
    type: SET_INVOICES_DATA_LOADING,
    payload: isLoading,
  };
}

function obtainInvoicesData(invoicesList, nextCursor) {
  return {
    type: "OBTAIN_INVOICES_DATA",
    payload: {
      invoicesList: invoicesList,
      nextCursor: nextCursor,
    },
  };
}

function obtainInvoicesError(errors) {
  return {
    type: "OBTAIN_INVOICES_ERROR",
    payload: {
      errors: errors,
    },
  };
}

function setInvoicesCancelToken(token) {
  return {
    type: SET_INVOICES_CANCEL_TOKEN,
    payload: token,
  };
}

export const getInvoices = (page, query) => (dispatch, getState) => {
  dispatch(setInvoicesDataLoading(true));

  const currentCancelToken = getState().invoices.invoicesListCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }

  if (page === 1) {
    dispatch(obtainInvoicesData([], null));
  }

  const nextCursor = getState().invoices.invoicesListNextCursor;

  return API.get("/financial_info", {
    cancelToken: new CancelToken(token => {
      dispatch(setInvoicesCancelToken(token));
    }),
    params: {
      card_number: query.cardNumber,
      doctor: query.doctor,
      date_from: query.startDate,
      date_by: query.endDate,
      status: query.billStatus,
      cursor: nextCursor,
    },
  }).then(
    response => {
      Logger.log(response);
      dispatch(setInvoicesDataLoading(false));
      let invoicesList = [...getState().invoices.invoicesList].concat(response.data.results);
      dispatch(obtainInvoicesData(invoicesList, response.data.next));
    },
    error => {
      dispatch(setInvoicesDataLoading(false));
      dispatch(obtainInvoicesError(error));
    },
  );
};
