import { SET_SNACKBAR_NOTIFICATION } from "../actions";

const initialState = {
  isShow: false,
  notification: "",
};

export default function snackbarNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SNACKBAR_NOTIFICATION:
      return { ...state, isShow: !!action.payload, notification: action.payload };
    default:
      return state;
  }
}
